<template>
	<div class="AddressInfo" style="padding-bottom:60px;">
		<div class="Title" style="padding: 10px 0 10px 10px;background:rgb(220,223,230)">发货地址</div>
		<div class="Contain" style="padding-top:15px;">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="寄件人姓名" prop="Name">
					<el-input auto-complete="on" v-model="ruleForm.Name" maxlength="20" placeholder="请输入寄件人姓名,最多输入20个字" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="寄件人手机号" prop="Phone">
					<el-input auto-complete="on" v-model="ruleForm.Phone" maxlength="11" placeholder="请输入寄件人手机号" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item prop="AddressArr" label="寄件地址" required>
					<el-cascader style="width: 300px;" :props="props" :options="dataList" clearable @change="handleAddressChange"
					 v-model="ruleForm.AddressArr" maxlength="100" />
				</el-form-item>
				<el-form-item prop="Address">
					<el-input type="textarea" v-model="ruleForm.Address"  style="width: 300px;" rows="3" placeholder="请填写详细地址"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="bottom-save-btn">
			<el-button type="primary" style="width:240px" @click="handleSave" :loading="Savestoreloading">保存</el-button>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import {} from '@/api/TurnTomySelf'
	import apiList from '@/api/other'
	export default {
		data(){
			return{
				Savestoreloading:false,
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				dataList: [],
				ruleForm: {
					Name: '',
					Phone:'',
					ProvinceId: '',
					CityId: '',
					AreaId: '',
					Province: '',
					City: '',
					Area: '',
					Address: '',
					AddressArr: [],
				},
				rules: {
					Name: [{
							required: true,
							message: '请输入寄件人姓名',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					Phone: [{
							required: true,
							message: '请输入寄件人手机号',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 11,
							message: '长度在 1 到 11个数字',
							trigger: 'blur'
						}
					],
					AddressArr: [{
						required: true,
						message: '请选择地址',
						trigger: 'change'
					}],
					Address: [{
						required: true,
						message: '请填写详细地址',
						trigger: 'blur'
					}],
				},
			}
		},
		created() {
			//获取地址
			this.getDataList()
		},
		beforeMount() {
			//初始化数据
			this.getData()
		},
		methods:{
			async getData(){
				let result = await apiList.expressAssistantIndex()
				this.ruleForm.Address= result.Result.Address
				this.ruleForm.AddressArr= [result.Result.Province,result.Result.City,result.Result.Area]
				this.ruleForm.AreaId= result.Result.Area
				this.ruleForm.CityId= result.Result.City
				this.ruleForm.Id= result.Result.Id
				this.ruleForm.Name= result.Result.Name
				this.ruleForm.Phone= result.Result.Phone
				this.ruleForm.ProvinceId=  result.Result.Province
			}, 
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v='+new Date().getTime(), {})
					.then(function(response) {
						that.dataList = response.data
					})
					.catch(function(error) {})
			},
			handleSave(){
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.Savestoreloading = true
						this.saveData();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		  async saveData(){
				try{
					let data = {
						Name: this.ruleForm.Name,
						Phone: this.ruleForm.Phone,
						Province: this.ruleForm.ProvinceId,
						City: this.ruleForm.CityId,
						Area: this.ruleForm.AreaId,
						Address: this.ruleForm.Address,
						Id: this.ruleForm.Id,
					}
					let result  = await  apiList.expressAssistantSave(data)
					if(result.IsSuccess){
						this.$message({
							showClose: true,
							type:'success',
							message:'保存成功'
						})
						this.getData()
					}
				} catch(e){
					console.log("e",e)
				}finally{
					this.Savestoreloading = false
				}
			},
			handleAddressChange() {
				this.ruleForm.ProvinceId = this.ruleForm.AddressArr[0]
				this.ruleForm.CityId = this.ruleForm.AddressArr[1]
				this.ruleForm.AreaId = this.ruleForm.AddressArr[2]
				this.dataList.map(item => {
					if (item.id == this.ruleForm.ProvinceId) {
						this.ruleForm.Province = item.name
						item.child.map(items => {
							if (items.id == this.ruleForm.CityId) {
								this.ruleForm.City = items.name
								items.child.map(lastitems => {
									if (lastitems.id == this.ruleForm.AreaId) {
										this.ruleForm.Area = lastitems.name
									}
								})
							}
						})
					}
				})
			},
		}
	}
</script>

<style lang="less">
	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}

	.AddressInfo {
		background: #fff;
		font-size: 14px;
	}
</style>
